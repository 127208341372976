/* eslint-disable react-hooks/exhaustive-deps */
//dependencies
import React, { useEffect } from "react";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { replaceUploadPath } from "../../../src/components/Helper";

//components
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Products from "../../interface/interface";

//image
import bannerImage from "../../assets/images/Office_01.png";

function Product() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState<Array<Products>>([]);
  const [productDataFilter, setProductDataFilter] = React.useState<
    Array<Products>
  >([]);
  const [locale, setLocale] = React.useState<any>(
    localStorage.getItem("i18nextLng")
  );

  /**
   * Local Storage
   */
  const localeData = localStorage.getItem("i18nextLng");

  /**
   * useEffect
   */
  useEffect(() => {
    getAllProduct();
    setLocale(localeData);
  }, [locale]);

  useEffect(() => {
    setProductDataFilter(product);
  }, [product]);

  /**
   * getAllProduct Api
   */
  const getAllProduct = () => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URI}v1/products?populate=*&locale=` + locale
    )
      .then((response) => response.json())
      .then((json) => {
        setProduct(json.data);
        setLoading(false);
      });
  };

  return (
    <div>
      <Header />
      <div
        className="headingBanner"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div className="container">
          <div className="headingBanner__content">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    navigate(`/${localeData}`);
                    setLoading(false);
                  }, 2000);
                }}
              >
                {t("breadcrumbs.home")}
              </Link>
              <Typography color="text.primary">
                {t("breadcrumbs.productsAndService")}
              </Typography>
            </Breadcrumbs>
            <h2 className="headingBanner__title">
              {t("breadcrumbs.productsAndService")}
            </h2>
          </div>
        </div>
      </div>

      <section className="serviceListBlock">
        <div className="container">
          <div className="row">
            {productDataFilter.map((item: Products, i: any) => {
              return (
                <div key={i} className="col-lg-6 col-md-12 mb-4">
                  <div
                    className="newsCard mx-3 my-4"
                    onClick={() => {
                      navigate(`/${localeData}/products/${item.id}`, {
                        state: item?.attributes,
                      });
                    }}
                  >
                    <div className="newsCard__img">
                      <img
                        src={`${process.env.REACT_APP_API_URI}${item?.attributes?.image?.data?.attributes?.url}`}
                        className="w-100 img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="newsCard__content">
                      <h5 className="newsCard__title">
                        {item?.attributes?.title}
                      </h5>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replaceUploadPath(
                            item?.attributes?.descriptions
                          ),
                        }}
                      ></div>
                      <div className="newsCard__btn">
                        {item?.attributes?.link}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />

      {isLoading && (
        <div className="loaderBox">
          <div className="loading-container">
            <div className="loading__blocks">
              <i className="line line--bottom"></i>
              <i className="line line--left"></i>
              <i className="line line--right"></i>
              <i className="line line--roof-left"></i>
              <i className="line line--roof-right"></i>
            </div>
            <span>Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Product;
