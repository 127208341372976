import { NavigateFunction } from "react-router-dom";
import New from "../../interface/interface";

interface NewsCardProps {
  item: New;
  navigate: NavigateFunction;
  localeData: string | null;
  replaceUploadPath: any;
}

const NewsCard: React.FC<NewsCardProps> = ({
  item,
  navigate,
  localeData,
  replaceUploadPath,
}) => {
  return (
    <div className="col-lg-4">
      <div
        className="newsCard"
        onClick={() => {
          navigate(`/${localeData}/news/${item.id}`, {
            state: item?.attributes,
          });
        }}
      >
        <div className="newsCard__img">
          <img
            src={`${process.env.REACT_APP_API_URI}${item?.attributes?.image?.data?.[0].attributes?.url}`}
            alt="newsImage"
            className="img-fluid"
          />
        </div>
        <div className="newsCard__content">
          <h5 className="newsCard__title">{item?.attributes?.title}</h5>
          <div
            dangerouslySetInnerHTML={{
              __html: replaceUploadPath(item?.attributes?.descriptions),
            }}
          ></div>
          <div className="newsCard__btn">{item?.attributes?.link}</div>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
