//dependencies
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import moment from "moment";
import { replaceUploadPath } from "../../../src/components/Helper";

//components
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./news.scss";

const initialNewsData = {
  headingImage: { data: null },
  label: "",
  title: "",
  mainTitle: "",
  descriptions: "",
  subTitle: "",
  subDescription: "",
  subImage: { data: null },
  image: { data: null },
  videoLink: { data: null },
};

function NewsDetails() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [locale, setLocale] = useState<any>(
    localStorage.getItem("i18nextLng") || "nb-NO"
  );

  const [newsData, setNewsData] = useState<any>(initialNewsData);

  const [allLocalizedData, setAllLocalizedData] = useState<any | null>(null);

  useEffect(() => {
    getNewsArticle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allLocalizedData) {
      const localizedContent = findLocalization(allLocalizedData, locale);
      if (localizedContent) {
        setNewsData((prev: any) => ({
          ...prev,
          title: localizedContent?.attributes?.title || "",
          mainTitle: localizedContent?.attributes?.mainTitle || "",
          descriptions: localizedContent?.attributes?.descriptions || "",
          subTitle: localizedContent?.attributes?.subTitle || "",
          subDescription: localizedContent?.attributes?.subDescription || "",
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, allLocalizedData]);

  const getNewsArticle = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}v1/homes/${id}?populate=*&locale=all`
      );

      if (!response.ok) {
        throw new Error(`Error fetching article: ${response.statusText}`);
      }

      const json = await response.json();
      const fetchedData = json?.data?.attributes;

      setAllLocalizedData(fetchedData);
      setNewsData({
        headingImage: fetchedData?.headingImage || { data: null },
        label: fetchedData?.label || "",
        title: fetchedData?.title || "",
        mainTitle: fetchedData?.mainTitle || "",
        descriptions: fetchedData?.descriptions || "",
        subTitle: fetchedData?.subTitle || "",
        subDescription: fetchedData?.subDescription || "",
        subImage: fetchedData?.subImage || { data: null },
        image: fetchedData?.image || { data: null },
        videoLink: fetchedData?.videoLink || { data: null },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const findLocalization = (data: any, locale: string): any => {
    return data?.localizations?.data?.find(
      (loc: any) => loc.attributes.locale === locale
    );
  };

  const publishedDate = moment(newsData?.publishedAt);
  const formattedDate = publishedDate.format("dddd, MMMM D YYYY");
  const localeData = locale;
  return (
    <div>
      <Header />
      {newsData?.headingImage?.data !== null && (
        <div
          className="headingBanner"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_URI}${newsData?.headingImage?.data?.[0].attributes?.url})`,
          }}
        >
          <div className="container">
            <div className="headingBanner__content">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  color="inherit"
                  onClick={() => {
                    navigate(`/${localeData}`);
                  }}
                >
                  {t("breadcrumbs.home")}
                </Link>

                <Link
                  color="inherit"
                  onClick={() => {
                    navigate(`/${localeData}/news`);
                  }}
                >
                  {newsData?.label}
                </Link>
                <Typography color="text.primary">{newsData?.title}</Typography>
              </Breadcrumbs>
              <h2 className="headingBanner__title"> {newsData?.title}</h2>
            </div>
          </div>
        </div>
      )}

      <div className="contentModule">
        <div className="container">
          <div className="contentModule__inner">
            <h3 className="contentModule__title">{newsData?.mainTitle}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: replaceUploadPath(newsData?.descriptions),
              }}
            ></div>
            <h5 className="contentModule__subTitle"> {newsData?.subTitle}</h5>
            <div
              dangerouslySetInnerHTML={{
                __html: replaceUploadPath(newsData?.subDescription),
              }}
            ></div>

            <div className="newsCard__date">{formattedDate}</div>

            {newsData?.subImage?.data !== null && (
              <img
                src={`${process.env.REACT_APP_API_URI}${newsData?.subImage?.data?.[0].attributes?.url}`}
                className="w-100 img-fluid"
                alt=""
              />
            )}
          </div>
        </div>
      </div>

      {newsData?.image?.data !== null && (
        <div className="videoBlock">
          <div className="container">
            <div className="videoBlock__inner">
              <img
                src={`${process.env.REACT_APP_API_URI}${newsData?.image?.data?.[0].attributes?.url}`}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="loaderBox">
          <div className="loading-container">
            <div className="loading__blocks">
              <i className="line line--bottom"></i>
              <i className="line line--left"></i>
              <i className="line line--right"></i>
              <i className="line line--roof-left"></i>
              <i className="line line--roof-right"></i>
            </div>
            <span>Loading...</span>
          </div>
        </div>
      )}
      {newsData?.videoLink?.data !== null && (
        <div className="videoBlock" style={{ background: "#fff" }}>
          <div className="container">
            <div className="videoBlock__inner" style={{ maxWidth: "100%" }}>
              <ReactPlayer
                url={`${process.env.REACT_APP_API_URI}${newsData?.videoLink?.data?.[0].attributes?.url}`}
                playing={true}
                controls={true}
                loop={true}
                muted={true}
                playsinline={true}
              />
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default NewsDetails;
