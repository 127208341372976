/* eslint-disable react-hooks/exhaustive-deps */
//dependencies
import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { replaceUploadPath } from "../../../src/components/Helper";

//components
import New from "../../interface/interface";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

//image
import bannerImage from "../../assets/images/pexels-cottonbro-studio-3944417.jpg";

//css
import "./news.scss";
import NewsCard from "./NewsCard";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// function a11yProps(index: number) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

function News() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const [newsData, setNewsData] = React.useState<Array<New>>([]);
  const [filterNews, setFilterNews] = React.useState<Array<New>>([]);
  const [isLoading, setLoading] = React.useState(false);
  const [locale, setLocale] = React.useState<any>(
    localStorage.getItem("i18nextLng")
  );

  const localeData = localStorage.getItem("i18nextLng");

  /**
   * useEffect
   */
  useEffect(() => {
    getAllNews();
    setLocale(localeData);
  }, [locale]);

  useEffect(() => {
    setFilterNews(newsData);
  }, [newsData]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  /**
   * getAllNews Api
   */
  const getAllNews = () => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URI}v1/homes?populate=*&locale=` + locale
    )
      .then((response) => response.json())
      .then((json) => {
        setNewsData(json.data);
        setLoading(false);
      });
  };

  const getLatestPublishedNews = () => {
    const sortedNews: any = newsData?.sort((a: any, b: any) => {
      return (
        new Date(b.attributes.publishedAt).getTime() -
        new Date(a.attributes.publishedAt).getTime()
      );
    });
    return sortedNews; //sortedNews?.length > 3 ? sortedNews.slice(0, 3) : sortedNews;
  };

  const sortedData = getLatestPublishedNews();
  return (
    <div>
      <Header />
      <div
        className="headingBanner"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div className="container">
          <div className="headingBanner__content">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => {
                  navigate(`/${localeData}`);
                }}
              >
                {t("breadcrumbs.home")}
              </Link>
              <Typography color="text.primary">{t("header.news")}</Typography>
            </Breadcrumbs>
            <h2 className="headingBanner__title"> {t("header.news")}</h2>
          </div>
        </div>
      </div>
      <Box className="newsPage">
        <div className="container">
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            ></Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <div className="row row-gap-3" style={{ gap: "60px 0px" }}>
              {/* start Map */}
              {sortedData?.map((item: New, i: any) => (
                <NewsCard
                  key={i}
                  item={item}
                  navigate={navigate}
                  localeData={localeData}
                  replaceUploadPath={replaceUploadPath}
                />
              ))}
            </div>
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <div className="contentModule">
              <div className="contentModule__inner">
                <h3 className="contentModule__title">
                  Lorem ipsum dolor sit met, consectetur adipiscing elit
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  ex velit, finibus vel mauris ac, mollis sagittis magna.
                  Praesent egestas lorem eget urna sagittis, vel rutrum nibh
                  convallis.
                </p>
                <h5 className="contentModule__subTitle">
                  Fusce ex velit finibus
                </h5>
                <p>
                  Quisque vulputate convallis hendrerit. Aenean consequat nulla
                  justo, at gravida elit congue id. Sed non magna dapibus,
                  vulputate lacus sit amet, sodales orci. Ut vehicula erat et
                  lectus tincidunt tristique. Aliquam dictum aliquet magna vel
                  luctus. Mauris rhoncus diam nec nisl gravida, a malesuada enim
                  convallis. Quisque accumsan sodales odio, sit amet consequat
                  sapien rhoncus pretium. Nam sed dui id turpis rhoncus
                  tincidunt.
                </p>
                <img src={bannerImage} alt="newsImage" className="img-fluid" />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  ex velit, finibus vel mauris ac, mollis sagittis magna.
                  Praesent egestas lorem eget urna sagittis, vel rutrum nibh
                  convallis.
                </p>
                <h5 className="contentModule__subTitle">
                  Fusce ex velit finibus
                </h5>
                <p>
                  Quisque vulputate convallis hendrerit. Aenean consequat nulla
                  justo, at gravida elit congue id. Sed non magna dapibus,
                  vulputate lacus sit amet, sodales orci. Ut vehicula erat et
                  lectus tincidunt tristique. Aliquam dictum aliquet magna vel
                  luctus. Mauris rhoncus diam nec nisl gravida, a malesuada enim
                  convallis. Quisque accumsan sodales odio, sit amet consequat
                  sapien rhoncus pretium. Nam sed dui id turpis rhoncus
                  tincidunt.
                </p>
              </div>
              <div className="row" style={{ gap: "60px 0px" }}>
                {filterNews
                  .filter(
                    (item: New) => item.attributes.category === "one" && locale
                  )
                  .map((item: New) => {
                    return (
                      <div className="col-lg-4">
                        <div className="newsCard">
                          <div className="newsCard__img">
                            <img
                              src={`${process.env.REACT_APP_API_URI}${item?.attributes?.image?.data?.[0].attributes?.url}`}
                              alt="newsImage"
                              className="img-fluid"
                            />
                          </div>
                          <div className="newsCard__content">
                            <h5 className="newsCard__title">
                              {item?.attributes?.title}
                            </h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  item?.attributes?.descriptions,
                              }}
                            ></p>
                            <div className="newsCard__btn">
                              {item?.attributes?.link}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="videoTemplate-mediaList">
              <div className="row" style={{ gap: "60px 0px" }}>
                {filterNews
                  .filter(
                    (item: New) => item.attributes.category === "two" && locale
                  )
                  .map((item: New) => {
                    return (
                      <div className="col-lg-4">
                        <div className="newsCard">
                          <div className="newsCard__img">
                            <img
                              src={`${process.env.REACT_APP_API_URI}${item?.attributes?.image?.data?.[0].attributes?.url}`}
                              alt="newsImage"
                              className="img-fluid"
                            />
                          </div>
                          <div className="newsCard__content">
                            <h5 className="newsCard__title">
                              {item?.attributes?.title}
                            </h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  item?.attributes?.descriptions,
                              }}
                            ></p>
                            <div className="newsCard__btn">
                              {item?.attributes?.link}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div className="videoTemplate-mediaList">
              <div className="row" style={{ gap: "60px 0px" }}>
                {filterNews
                  .filter(
                    (item: New) =>
                      item.attributes.category === "three" && locale
                  )
                  .map((item: New) => {
                    return (
                      <div className="col-lg-4">
                        <div className="newsCard">
                          <div className="newsCard__img"
                          >
                            <img
                              src={`${process.env.REACT_APP_API_URI}${item?.attributes?.image?.data?.[0].attributes?.url}`}
                              alt="newsImage"
                              className="img-fluid"
                            />
                          </div>
                          <div className="newsCard__content">
                            <h5 className="newsCard__title">
                              {item?.attributes?.title}
                            </h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  item?.attributes?.descriptions,
                              }}
                            ></p>
                            <div className="newsCard__btn">
                              {item?.attributes?.linkEN}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </TabPanel> */}
        </div>
      </Box>
      <Footer />
      {isLoading && (
        <div className="loaderBox">
          <div className="loading-container">
            <div className="loading__blocks">
              <i className="line line--bottom"></i>
              <i className="line line--left"></i>
              <i className="line line--right"></i>
              <i className="line line--roof-left"></i>
              <i className="line line--roof-right"></i>
            </div>
            <span>Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default News;
