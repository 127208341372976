/* eslint-disable react-hooks/exhaustive-deps */
//dependencies
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { replaceUploadPath } from "../../../src/components/Helper";

//components
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import News from "../../interface/interface";

//image

//css
import "./details.scss";
import NewsCard from "../News/NewsCard";

function Details() {
  const { id } = useParams();
  // let id = detailId?.split("-")[1];
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [locale, setLocale] = useState<any>(localStorage.getItem("i18nextLng"));
  const [newsData, setNewsData] = useState<Array<News>>([]);
  const [filterNews, setFilterNews] = useState<Array<News>>([]);
  const [product, setProduct] = useState<any>([]);
  const localeData = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (id) {
      getProduct(id, locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, locale]);

  useEffect(() => {
    getAllNews(locale);
  }, [locale]);

  useEffect(() => {
    setFilterNews(newsData);
  }, [newsData]);

  const getAllNews = async (currentLocale: string) => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URI}v1/homes?populate=*&locale=${currentLocale}`
    );

    const json = await response.json();
    const data = json?.data;
    if (!response) {
      throw new Error("Error fetching article");
    }

    setNewsData(data);
  };

  const fetchedProducts = new Set<string>();

  const getProduct = async (id: string, currentLocale: string) => {
    const uniqueKey = `${id}_${currentLocale}`;

    // Check if the product has already been fetched to avoid recursion
    if (fetchedProducts.has(uniqueKey)) {
      return;
    }

    fetchedProducts.add(uniqueKey);

    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}v1/products/${id}?populate=*&locale=${currentLocale}`
      );

      if (!response.ok) {
        throw new Error("Error fetching product");
      }

      const json = await response.json();
      const data = json?.data;

      const localizedId = findLocalizationId(data, currentLocale);
      if (localizedId) {
        await getProduct(localizedId, currentLocale); // Recursive call
      } else {
        setProduct(data?.attributes);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const findLocalizationId = (
    data: any,
    targetLocale: string
  ): string | null => {
    const localization = data?.attributes?.localizations?.data.find(
      (loc: any) => loc.attributes.locale === targetLocale
    );
    return localization?.id || null;
  };

  const sortProductNews = () => {
    const sorted: any = product?.news?.data?.sort((a: any, b: any) => {
      return (
        new Date(b?.attributes?.createdAt).getTime() -
        new Date(a?.attributes?.createdAt).getTime()
      );
    });
    return sorted;
  };

  const sortedProductNews = sortProductNews();

  return (
    <div>
      <Header />
      {product?.headingImage?.data !== null && (
        <div
          className="headingBanner"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_URI}${product?.headingImage?.data?.[0]?.attributes?.url})`,
          }}
        >
          <div className="container">
            <div className="headingBanner__content">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  color="inherit"
                  onClick={() => {
                    navigate(`/${localeData}`);
                  }}
                >
                  {t("breadcrumbs.home")}
                </Link>

                <Link
                  color="inherit"
                  onClick={() => {
                    navigate(`/${localeData}/products`);
                  }}
                >
                  {product?.label}
                </Link>
                <Typography color="text.primary">{product?.title}</Typography>
              </Breadcrumbs>
              <h2 className="headingBanner__title"> {product?.title}</h2>
            </div>
          </div>
        </div>
      )}

      <div className="contentModule">
        <div className="container">
          <div className="contentModule__inner">
            <h3 className="contentModule__title">{product?.mainTitle}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: replaceUploadPath(product?.descriptions),
              }}
            ></div>
            <h5 className="contentModule__subTitle"> {product?.subTitle}</h5>
            <div
              dangerouslySetInnerHTML={{
                __html: replaceUploadPath(product?.subDescription),
              }}
            ></div>

            {product?.subImage?.data !== null && (
              <img
                src={`${process.env.REACT_APP_API_URI}${product?.subImage?.data?.[0]?.attributes?.url}`}
                className="w-100 img-fluid"
                alt=""
              />
            )}
          </div>
        </div>
      </div>

      <div className="newsBlock" style={{ background: "#F4FAFC" }}>
        <div className="container">
          <div className="newsBlock__heading">
            <h6 className="newsBlock__subTitle">{t("home.newsAbout")}</h6>
            <h5 className="newsBlock__title">{t("home.areYouUpdated?")}</h5>
          </div>
          <div className="row">
            {sortedProductNews?.map((item: any) => {
              return filterNews
                .filter((itemNews: any) => itemNews?.id === item?.id)
                .map((itemNews: News, i: any) => {
                  return (
                    <NewsCard
                      key={i}
                      item={itemNews}
                      navigate={navigate}
                      localeData={localeData}
                      replaceUploadPath={replaceUploadPath}
                    />
                  );
                });
            })}
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="loaderBox">
          <div className="loading-container">
            <div className="loading__blocks">
              <i className="line line--bottom"></i>
              <i className="line line--left"></i>
              <i className="line line--right"></i>
              <i className="line line--roof-left"></i>
              <i className="line line--roof-right"></i>
            </div>
            <span>Loading...</span>
          </div>
        </div>
      )}

      {product?.image?.data !== null && (
        <div className="videoBlock">
          <div className="container">
            <div className="videoBlock__inner">
              <img
                src={`${process.env.REACT_APP_API_URI}${product?.image?.data?.attributes?.url}`}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {product?.videoLink?.data !== null && (
        <div className="videoBlock" style={{ background: "#fff" }}>
          <div className="container">
            <div className="videoBlock__inner" style={{ maxWidth: "100%" }}>
              <ReactPlayer
                url={`${process.env.REACT_APP_API_URI}${product?.videoLink?.data?.[0]?.attributes?.url}`}
                playing={true}
                controls={true}
                loop={true}
                muted={true}
                playsinline={true}
              />
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Details;
